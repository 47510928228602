import React from "react";
import {
	ApplicationFormText,
	ApplicationTeamSizeSelect,
} from "@components/ApplicationStepPage/ApplicationStepPage";
import {} from "@components";
import {
	useAcademiaFormState,
	ACADEMIA_FORM_STEPS,
} from "../_useAcademiaFormState";
import { AcademiaStepPage } from "../_AcademiaStepPage";

const AcademiaTeam: React.FunctionComponent = () => {
	const {
		getInputProps,
		navigateToStep,
		setForceShowError,
		theseFieldsHaveValues,
	} = useAcademiaFormState(ACADEMIA_FORM_STEPS.TEAM.formStep);

	const onSubmit = () => {
		setForceShowError(true);

		// Make sure everything has a value
		if (
			theseFieldsHaveValues(
				"0-2/research_lab_location",
				"0-2/institution_name",
				"0-2/research_lab_name",
				"0-2/research_lab_website_url",
				"team_size_range"
			)
		) {
			navigateToStep(ACADEMIA_FORM_STEPS.USE.formStep);
		}
	};

	return (
		<AcademiaStepPage
			currentStepIndex={ACADEMIA_FORM_STEPS.TEAM.formStep}
			onButtonClick={onSubmit}
			onBackClick={() =>
				navigateToStep(ACADEMIA_FORM_STEPS.CONTACT.formStep)
			}
		>
			<ApplicationFormText
				{...getInputProps("0-2/research_lab_name")}
				required
			/>
			<ApplicationFormText
				{...getInputProps("0-2/research_lab_website_url")}
				required
			/>

			<ApplicationFormText
				{...getInputProps("0-2/research_lab_location")}
				required
			/>

			<ApplicationFormText
				{...getInputProps("0-2/institution_name")}
				required
			/>
			<ApplicationTeamSizeSelect {...getInputProps("team_size_range")} />
		</AcademiaStepPage>
	);
};

export default AcademiaTeam;
